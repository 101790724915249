



























import {Component, Prop, Vue} from "vue-property-decorator";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {namespace} from "vuex-class";
import PayrollService from "@/services/payroll/PayrollService";

const AppModule = namespace("App");

@Component({
  components: {FileUpload}
})
export default class UploadK1Modal extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private onFilesUploaded!: () => void;

  @Prop()
  private shareholderId!: number;

  private period = "";

  private isPeriodEntered = false;

  onUploaded(files: FileMetaDTO[]) {
    PayrollService.saveFormK1(this.shareholderId, files[0].id, this.period).then(
        () => {
          this.onFilesUploaded();
          this.$modal.hideAll();
        }
    )
  }


}
